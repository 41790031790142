:root {
  --bs-primary: #0f04e6;
  --yellow: #f7b521;
  --light-yellow: #dcd793;
  --brown: #a56519;
  --light-blue: #2196f3;
  --light-blue: #2196f3;
  --light-blue-200: #1976d2;
  --dark-green: #192d2f;
  --light-gray: #686868;
  --white: #ffffff;
}
/* Common css Start */
.container {
  max-width: 600px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}
.btn-secondary {
  --bs-btn-bg: var(--bs-orange);
  --bs-btn-border-color: var(--bs-orange);
  --bs-btn-hover-bg: var(--bs-orange);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-border-color: var(--bs-orange);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-bg: var(--bs-orange);
  --bs-btn-active-border-color: var(--bs-orange);
}
.store_tabs {
  /* max-width: 600px; */
  /* margin: 0 auto; */
}

.tc-login-section {
  height: 100%;
}
.text-primary {
  color: var(--bs-primary) !important;
}
.text-secondary {
  color: var(--bs-orange) !important;
}
/* no data found */
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.no-data-found img {
  width: 320px;
}
/* no data found */

/* loader css Start */
.tc-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.8px);
  -webkit-backdrop-filter: blur(6.8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}
.tc-page-loader svg {
  width: 84px;
  height: 84px;
}
/* loader css end */

/* Login Section START */
.tc-login-navbar {
  background: #0f04e6;
  position: fixed;
  height: 62px;
  left: 0;
  top: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 99;
  max-width: 100%;
  padding: 15px 0;
}
.tc-login-navbar h2 {
  font-size: 27px;
  color: #167775;
  text-align: center;
  font-family: "Canaro";
  margin: 0;
}

.tc-login-footer-section {
  background: #fd7e14;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 99;
  max-width: 100%;
  height: 62px;
}

.tc-login-section {
  height: 100%;
}

.tc-login-content {
  padding: 75px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  /* margin: 62px 0; */
}

.tc-login-content .tc-logo {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  color: #6697a1;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Biome";
}

.tc-login-content .tc-number-input {
  background: #fdfdfd;
  border: 1px solid #a6e1df;
  border-radius: 10px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #7f7f7f;
  padding: 9px 10px;
  margin-bottom: 20px;
}

.tc-login-content .tc-number-input input {
  border: 0;
}

.tc-login-form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.tc-login-form .tc-login-form-box {
  margin: auto 0;
}

.tc-login-form .tc-login-top-space {
  height: 30px;
  margin-bottom: auto;
}

.tc-login-form .tc-login-form-item {
  margin-bottom: 50px;
}

.tc-login-form .tc-login-form-list {
  margin-bottom: 15px;
}

.tc-login-form .tc-login-form-list .tc-login-form-list-input-row {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  align-items: center;
}

.sign-up {
  text-align: end;
  margin-top: 20px;
}
.sign-in {
  text-align: center;
  margin-top: 20px;
}

.form-err {
  color: #f00;
  text-align: start;
}

.tc-login-form .tc-login-form-list .tc-login-option {
  border: 3px solid #0f04e6;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  box-shadow: none;
  border-radius: 10px;
}
.tc-login-form .tc-login-form-list .tc-login-option::placeholder {
  color: #c4c4c4;
}

.tc-login-form .tc-login-form-list label {
  margin-right: 15px;
}

.tc-login-form .tc-login-form-list {
  margin-bottom: 15px;
}

.login-button {
  width: 100%;
  justify-content: center;
  border-radius: 100px;
  background: #0f04e6;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 10px 10px 30px;
  font-weight: 600;
}

.login-button svg {
  width: 22px;
  height: 22px;
  margin-left: 12px;
}

.tc-login-form .tc-logo {
  font-size: 30px;
  display: block;
  margin-bottom: 35px;
  text-decoration: none;
}

.tc-login-form .tc-logo img {
  width: 80%;
}

/* Login Section END */
/* Button START */
.tc-save {
  position: relative;
  padding: 10px 20px;
  background: #0f04e6;
  border: 1px solid #0f04e6;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  margin: 0 auto;
  width: fit-content;
  border-radius: 10px;
  display: flex;
}

.tc-save.active,
.tc-save:hover {
  background-color: #0b00da !important;
  border-color: #0b00da !important;
}

.tc-save:disabled {
  background-color: #ccd3d3;
  border: 1px solid #e1f1f1;
}

/* Button END */
/* Err msg */
.tc-error-text {
  color: #f00000;
  font-size: 14px;
  font-weight: 600;
}
/* Err msg */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
}
.main_content {
  flex: 1;
  margin-top: 60px;
  margin-bottom: 60px;
  overflow: auto;
}

.game-main-content {
  margin-top: 0;
  margin-bottom: 0;
}

.flex_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.home_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex_wrapper .reload-button {
  background: transparent;
  border: none;
}
/* Header */
.fb_navbar {
  background-color: var(--bs-primary);
  padding: 5px 10px;
  color: var(--bs-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.fb_navbar .nav-link {
  padding: 10px;
  display: flex;
  align-items: center;
}
.fb_navbar .nav-link svg,
.fb_navbar .nav-link img {
  margin-right: 10px;
}
.fb_navbar .ft_icon {
  width: auto;
  height: 30px;
}
.rocket_icon svg {
  transform: rotate(-90deg);
}
.user_icon svg {
  width: 32px;
  margin-right: 6px;
}

.user_icon .dropdown-toggle::after {
  display: none;
}

.user_icon .badge {
  top: -5px;
  right: -5px;
}
.ticket_icon svg {
  /* transform: rotate(-45deg); */
}
.ticket_icon .tickets {
  font-size: 0.75rem;
  text-align: center;
}
.point_text {
  background-color: var(--bs-white);
  color: var(--bs-dark);
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
}
.dropdown-menu {
  --bs-dropdown-link-color: var(--bs-orange);
  --bs-dropdown-link-hover-color: var(--bs-dark);
  --bs-dropdown-border-color: var(--bs-orange);
}
.dropdown-menu a {
  color: var(--bs-orange);
  text-decoration: none;
}
.dropdown-divider {
  border-color: var(--bs-orange);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='27' viewBox='0 0 32 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 24.7273C0 24.3415 0.153247 23.9715 0.426027 23.6988C0.698807 23.426 1.06878 23.2727 1.45455 23.2727H30.5455C30.9312 23.2727 31.3012 23.426 31.574 23.6988C31.8468 23.9715 32 24.3415 32 24.7273C32 25.113 31.8468 25.483 31.574 25.7558C31.3012 26.0286 30.9312 26.1818 30.5455 26.1818H1.45455C1.06878 26.1818 0.698807 26.0286 0.426027 25.7558C0.153247 25.483 0 25.113 0 24.7273ZM0 13.0909C0 12.7051 0.153247 12.3352 0.426027 12.0624C0.698807 11.7896 1.06878 11.6364 1.45455 11.6364H30.5455C30.9312 11.6364 31.3012 11.7896 31.574 12.0624C31.8468 12.3352 32 12.7051 32 13.0909C32 13.4767 31.8468 13.8466 31.574 14.1194C31.3012 14.3922 30.9312 14.5455 30.5455 14.5455H1.45455C1.06878 14.5455 0.698807 14.3922 0.426027 14.1194C0.153247 13.8466 0 13.4767 0 13.0909ZM0 1.45455C0 1.06878 0.153247 0.698807 0.426027 0.426027C0.698807 0.153247 1.06878 0 1.45455 0H30.5455C30.9312 0 31.3012 0.153247 31.574 0.426027C31.8468 0.698807 32 1.06878 32 1.45455C32 1.84031 31.8468 2.21028 31.574 2.48306C31.3012 2.75584 30.9312 2.90909 30.5455 2.90909H1.45455C1.06878 2.90909 0.698807 2.75584 0.426027 2.48306C0.153247 2.21028 0 1.84031 0 1.45455Z' fill='white'/%3E%3C/svg%3E%0A");
}
.navbar-collapse {
  background-color: var(--bs-gray-500);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.navbar-toggler {
  padding: 0;
  border: 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}
/* Footer */
footer,
.store_tabs {
  background-color: var(--bs-orange);
  padding: 5px 10px;
  height: 60px;
}
.fb_footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.fb_footer a {
  text-align: center;
  color: var(--bs-white);
  text-decoration: none;
}
.fb_footer small {
  font-size: 0.75rem;
}
.fb_footer .ft_icon {
  width: auto;
  height: 30px;
}
/* Loader */
.loading_bar {
  display: flex;
  height: 20px;
  padding: 2px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  border: 2px solid var(--yellow);
  border-radius: 50px;
  overflow: hidden;
}
.loading_bar .progress_bar {
  display: flex;
  height: 100%;
  width: 90%;
  background: var(--yellow);
  border-radius: 50px;
  animation: progress-animation 2.5s ease-in-out;
}
.loading_text {
  text-align: center;
  color: var(--yellow);
  margin-bottom: 0;
}
.preloader_wrapper {
  z-index: 3;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 120px);
  background-color: var(--bs-white);
}
@keyframes progress-animation {
  0% {
    width: 0%;
  }
  20% {
    width: 10%;
  }
  40% {
    width: 30%;
  }
  50% {
    width: 60%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}
/* Common css End */
.host_wrap {
  text-align: center;
  margin-top: 70px;
}
/* .home_content {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0px;
  bottom: 60px;
  background-color: var(--light-yellow);
} */
.screen_bg {
  position: relative;
  z-index: 1;
  height: calc(100vh - 120px);
  width: 100%;
  overflow: hidden;
  background: #ffffff;
}
.screen_bg video,
.screen_bg iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-fit: fill;
}

.benefit_poster_container video {
  height: 92% !important;
}

.poster_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home_content {
  margin-top: auto;
}

.play_home.christmas_win .btn {
  bottom: 90px;
}

.play_home .btn {
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  letter-spacing: 2px;
  font-weight: 500;
}

.benefit_screen_btn .btn {
  bottom: 10px;
}

.play_home:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 50%);
}
.medium-size-btn {
  width: 165px !important;
}
.win_wrapper,
.lose_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  /* padding: 60px 20px; */
  padding: 100px 20px;
  justify-content: space-around;
  overflow: auto;
}

.last_game_win_reward_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 6px 20px;
  justify-content: space-around;
  overflow: hidden;
}

.win_text,
.lose_text {
  color: var(--bs-orange);
  font-weight: 700;
  font-size: 2rem;
  padding: 0 0 30px;
}
.win_reward {
  color: var(--bs-primary);
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 20px;
}

.activity_win_reward {
  color: var(--bs-primary);
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 70px;
}
.win_img img,
.lose_img img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

.img-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.img_for_peso {
  max-width: 400px !important;
}

.peso_img_text {
  position: absolute;
}

.win_lvl_btn .btn {
  letter-spacing: 2px;
}
.learn_loader {
  padding: 20px;
}
.learn_loader img {
  max-width: 150px;
}
.learn_loader h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.text_light_yellow {
  color: var(--brown);
}
.bingo_loader {
  width: 100%;
}
.leader_board {
  display: flex;
  justify-content: center;
}
.leader_board_wrapper {
  border: 1px solid var(--bs-primary);
  border-radius: 30px;
  padding: 20px;
}
.leader_board .rank {
  background-color: var(--light-blue);
  color: var(--bs-white);
  display: flex;
  flex-direction: column;
}
.store_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.store_tabs {
  margin-top: auto;
  z-index: 1031;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.store_tabs .nav-item {
  display: flex;
  align-items: center;
}
.store_tabs .nav-link {
  color: var(--bs-white);
  border: 2px solid transparent;
  font-weight: 500;
}
.store_tabs .nav-link.active {
  border-color: var(--bs-white);
}
.store_col {
  /* min-height: 30vh; */
  border: 1px solid var(--bs-orange);
  border-top: 0;
}
.row .store_col:nth-child(odd) {
  padding-right: 24px;
  border-left: 0;
}
.row .store_col:nth-child(even) {
  padding-left: 24px;
  border-right: 0;
  border-left: 0;
}
.store_item_wrapper {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.store_item {
  background-color: var(--dark-green);
  color: var(--bs-white);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20% 0;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  min-height: 80px;
}
.store_item img {
  max-width: 100px;
}
.orange_card {
  background-color: var(--bs-orange);
  color: var(--bs-white);
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  margin-bottom: 1rem;
}
.orange_card svg,
.orange_card .svg-icon {
  width: 60px;
  flex-shrink: 0;
}
.white_btn {
  color: var(--bs-dark);
  font-weight: 600;
  min-width: 90px;
}
.white_btn:hover {
  color: var(--bs-primary);
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
}
.boost_wrapper .boost_card:nth-child(even),
.broadcast_wrapper .broadcast_card:nth-child(even) {
  background-color: var(--bs-primary);
}
.broadcast_card img,
.benifit_card img {
  width: 115px;
}
.benifits_card_wrapper .card_img {
  max-width: 200px;
}
.broadcast_card .card-text {
  font-size: 1.5rem;
}
.center_align {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.profile_header {
  border: 2px solid var(--bs-orange);
  padding: 5px;
  border-radius: 15px;
  margin-left: -30px;
  text-align: center;
}
.profile_img svg {
  width: 65px;
}
.profile_card_img img {
  max-width: 300px;
  width: 100%;
}

/* GAME SCREEN CSS START */
.display-inital-game {
  visibility: visible;
}
.not-display-game {
  visibility: hidden;
}

.game-content {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  /* height: calc(100vh - 120px); */
  height: 100vh;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
}
.game-initailize {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* padding-top: 56.25%; */
  height: 100%;
}
.game-initailize iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.game-content .game-rotate-phone {
  z-index: 2;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bs-white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.game-content .game-rotate-phone img {
  width: 80px;
  height: auto;
}
.game-rotate-phone-second {
  z-index: 2;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bs-white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.game-rotate-phone-second img {
  width: 80px;
  height: auto;
}
/* GAME SCREEN CSS END */

/* modal css START */
.modal_corner_close_btn_container {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0px 7px 0px 0px;
}
.modal_corner_close_btn {
  background-color: initial;
  border: none;
  font-size: 25px;
  cursor: pointer;
  height: fit-content;
  margin: 4px 4px 0px 0px;
}
.tc-play-modal .modal-content {
  border-radius: 30px;
}
.tc-play-modal .modal-body {
  padding: 2rem;
}

.tc-play-modal .modal-content .modal-body .tc-play-modal-text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 1rem;
}

.tc-play-modal .modal-content .modal-body .tc-play-modal-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.tc-play-modal-img-container img {
  width: 80px;
}

.modal-text-sm {
  font-size: 16px !important;
}

.tc-play-modal .tc-play-modal-btn {
  text-align: center;
  margin-top: 30px;
}
.tc-play-modal .tc-play-modal-btn button {
  border: 0;
  margin: 0 10px;
  font-weight: 600;
  background: var(--bs-orange);
  padding: 4px 20px;
  border-radius: 12px;
  color: #ffffff;
  min-width: fit-content;
  font-size: 23px;
}
.modal-cancel-btn {
  background: var(--light-gray) !important;
}
.modal-second-btn {
  width: 112px !important;
}

/* modal css end */

/* text colors start */
.text-blue {
  color: var(--bs-primary) !important;
}
.text-white-color {
  color: var(--white) !important;
  font-size: 64px;
}
/* .liveness_level img {
  max-width: 130px;
} */
/* text colors end*/

/* Prototype2 CSS */
.home_wrapper .swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: auto;
  left: 8px;
}

.home_wrapper .swiper-pagination-bullet {
  border: 1px solid var(--bs-orange);
  width: 15px;
  height: 15px;
  background: white;
  opacity: 1;
  position: relative;
}
.home_wrapper .swiper-pagination-bullet:after {
  content: "";
  border: 2px solid white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.home_wrapper .swiper-pagination-bullet-active {
  background: var(--bs-orange);
}
.swiper {
  width: 100%;
  height: 100%;
}

.gilas-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.benifits_card_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
/* .liveness_level {
  border: 2px solid var(--bs-orange);
  border-radius: 50px;
  padding: 50px 30px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
.level-btn {
  font-size: 20px;
  text-decoration: none;
  font-weight: 500;
  color: black;
  text-transform: uppercase;
  background: transparent;
  border: none;
}
.orange_outline_card {
  border: 2px solid var(--bs-orange);
  border-radius: 50px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orange_outline_card .bdr-bottom {
  border-bottom: 2px solid var(--bs-orange);
}
.benifitReceive_card img {
  width: 60px;
}
.big-text {
  font-size: 50px;
  letter-spacing: 1px;
}

.tap-btn {
  margin-top: 20px;
}
.orange_flat_card {
  border: 0;
  text-align: center;
  height: 100%;
}
.orange_flat_card img {
  max-width: 100px;
}
.orange_flat_card .point_text {
  background: var(--bs-orange);
  color: var(--white);
  font-weight: normal;
}
.orange_flat_card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
/* Tutorial CSS */
.tutorial-slider .gilas-slide .tutorial-img {
  max-width: 180px;
}
.tutorial-slider a {
  text-decoration: none;
  color: var(--bs-body-color);
}
.tutorial-slider .gilas-slide h1 {
  /* margin: 30px 0 0; */
}
.tutorial-slider .gilas-slide {
  height: calc(100% - 45px);
  /* border: 2px solid var(--bs-primary); */
  max-width: 80%;
  margin: 0 auto;
  padding: 10px;
  justify-content: space-around;
}
.landing_page_wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.landing_page_wrapper img {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  object-fit: contain;
}
/* Auth CSS */
.blank-header,
.blank-footer {
  color: var(--bs-white);
  padding: 5px 10px;
  height: 60px;
  position: fixed;
  left: 0;
  right: 0;
}
.blank-header {
  background-color: var(--bs-primary);
  top: 0;
}
.blank-footer {
  background-color: var(--bs-orange);
  bottom: 0;
}
.auth-main-content {
  height: calc(100vh - 120px);
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth-form-wrapper {
  width: 100%;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
}
.form-control {
  font-weight: 500;
}
.form-control::placeholder {
  font-weight: normal;
}
.form-control,
.form-select {
  border-color: var(--bs-orange);
}
.form-control:focus,
.form-select:focus {
  border-color: var(--bs-orange);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253 126 20 / 25%);
}
.signup-step-2 .form-select:focus,
.signup-step-2 .form-control:focus {
  box-shadow: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper
  .react-datepicker__input-container
  .sigla-date-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  border-color: var(--bs-orange);
  box-shadow: none;
  outline: none;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  text-align: left !important;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.birthdate-container {
  position: relative;
}

input[type="date"]::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.birthdate-container input[type="date"] + .date-place-holder {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
}

.birthdate-container input[type="date"]:focus + .date-place-holder {
  display: none;
}

.tc-login-form .react-select-dropdown .css-13cymwt-control,
.signup-step-2 .react-select-dropdown .css-13cymwt-control {
  border: none !important;
  box-shadow: none !important;
}

.tc-login-form .react-select-dropdown .css-t3ipsp-control,
.signup-step-2 .react-select-dropdown .css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
}

.auth-form-wrapper [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
.auth-form-wrapper [type="file"] + label {
  border: 1px solid var(--bs-orange);
  color: black;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 15px 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}
.otp-field .form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid var(--bs-orange);
  text-align: center;
  flex-shrink: 0;
  flex-grow: 1;
  font-weight: 700;
  font-size: 18px;
  padding: 6px 0;
}
.otp-field .form-control:focus {
  box-shadow: none;
}

.signup-step-3 .submit-btn {
  margin-top: 20px;
}

.signup-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

::-webkit-calendar-picker-indicator {
  background-image: url("../images/calendar.png");
}
.ScratchCard__Container {
  margin: 0 auto;
}
canvas.ScratchCard__Canvas {
  left: 0;
  right: 0;
  bottom: 0;
}
.scrach-img {
  width: 300px;
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.home-card {
  display: flex;
  align-items: center;
  background: #fcf2cf;
  border: 1px solid var(--bs-primary);
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 15px 30px;
}

.cm-file-upload {
  width: 100%;
  height: 90%;
}
.cm-file-upload.h-80 {
  height: 80%;
}
.cm-file-upload [type="file"] + label {
  max-width: 100%;
  height: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #ed7d31;
}
.orange-bdr {
  border: 1px solid var(--bs-orange);
}
.selfie-box {
  border: 1px solid var(--bs-orange);
  height: calc(100% - 25px);
  margin: 10px 0;
  width: 100%;
}

.selfie-btn {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selfie-box-wrapper {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bigger-btn {
  padding: 1rem 3rem;
}

.selfie-passed {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
}

.nav-center-title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}

.selfie-box video {
  width: 100%;
  height: 100%;
}
.fill-img {
  width: 100%;
  height: 100%;
}
.fill-img img {
  height: 100%;
  width: 100%;
}

.passed-img img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 3px solid green;
}

.selfie-img img {
  object-fit: contain;
}

.liveness_level {
  width: 100%;
  height: 100%;
}

.liveness-btn {
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 375px) {
  .broadcast_card img {
    width: 80px;
  }
  .broadcast_card .card-text {
    font-size: 1rem;
  }
  .profile_details {
    font-size: 0.85rem;
  }
  .point_text {
    font-size: 0.85rem;
  }
  /* .liveness_level {
    min-height: auto;
  } */
  .landing_page_wrapper img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}
.win-retry {
  /* margin-top: 151px; */
  margin-bottom: 120px;
}
.last_prize_img {
  height: calc(100vh - 208px) !important;
}
